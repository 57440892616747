<template>
  <div id="flightList" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4 flex-between-center">
        <div class="N-Page T-size-36">Aircraft Fueling</div>
      </div>
    </div>
    <div id="calendarDATA" class="wrap-Main">
      <div class="box-S2">
        <CompListFlight
          ref="CompListFlight"
          :DataDateFromCalendar="datadatefromcalender"
          :Service="'fuelselect'"
        ></CompListFlight>
      </div>
      <div class="box-S2">
        <calendar
          :Service="'fuelselect'"
          @DataCalender="BindingDataFromCalendar"
        ></calendar>
      </div>
    </div>
    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="Back()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import calendar from "../components/main/Calendar";
import CompListFlight from "../components/main/CompList";
import { startOfDay, endOfDay, format } from "date-fns";
var today = new Date();
export default {
  data: () => ({
    datadatefromcalender: new Date().toISOString().substr(0, 10),
    userdata: "",
  }),
  components: {
    calendar,
    CompListFlight,
  },
  async mounted() {
    this.BindingDataFromCalendar(format(today, "yyyy-MM-dd"));
    this.userdata = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    Back() {
      this.$router.go(-1);
    },
    async BindingDataFromCalendar(dataDate) {
      this.datadatefromcalender = dataDate;
      await this.$refs.CompListFlight.renderListFlight(dataDate);
    },
    async GotoCreateFlight() {
      try {
        this.$router.push({ path: "/flightdetail/Create/null" });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style>
</style>